<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Waste Streams</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link
                to="/waste-streams/create"
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="Add New Type"
                  @click="navigate"
                >
                  <i class="fas fa-plus-circle pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <template>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <b-form-input
                    v-model="search"
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Filter by waste streams"
                  ></b-form-input>
                </div>
                <div hidden class="col-md-4 col-sm-12">
                  <multiselect
                    v-model.trim="unit"
                    :options="units"
                    track-by="name"
                    label="name"
                    class="select-sm"
                    placeholder="Filter by units"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </multiselect>
                </div>
              </div>
              <hr />
              <v-data-table
                :headers="headers"
                :items="streams"
                :items-per-page="10"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <div class="w-100 text-right">
                    <span
                      @click="viewDetail(item)"
                      v-b-tooltip.hover.top="'Details'"
                      class="btn btn-icon btn-light-primary btn-hover-light-primary btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-horizontal.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="editItem(item.id)"
                      v-b-tooltip.hover.top="'Edit'"
                      class="btn btn-icon btn-light-warning btn-hover-light-warning btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-warning">
                        <inline-svg
                          src="media/svg/icons/Communication/Write.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="deleteItem(item.id)"
                      v-b-tooltip.hover.top="'Delete'"
                      class="btn btn-icon btn-light-danger btn-hover-light-danger btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                      </span>
                    </span>
                  </div>
                </template>
              </v-data-table>
              <b-modal
                ref="item-detail"
                hide-footer
                title="Waste Stream Detail"
              >
                <div class="d-block">
                  <div class="row">
                    <div class="col-md-5">Name:</div>
                    <div class="col-md-7 mb-3">
                      <b>{{ name }}</b>
                    </div>
                    <div class="col-md-5">Description:</div>
                    <div class="col-md-7 mb-3">
                      <b>{{ description }}</b>
                    </div>
                    <div class="col-md-5">Unit:</div>
                    <div class="col-md-7">
                      <b>{{ unit_name }}</b>
                    </div>
                    <div class="col-md-12 d-flex justify-content-end">
                      <b-button
                        class="btn btn-light-danger font-weight-bold mt-3"
                        @click="hideDetail"
                        >Close</b-button
                      >
                    </div>
                  </div>
                </div>
              </b-modal>
            </template>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import WasteStreamService from "@/core/services/waste-stream.service.js";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    return {
      search: "",
      description: "",
      name: "",
      emission_factor: "",
      unit_name: "",
      headers: [
        {
          text: "Waste Stream",
          align: "start",
          value: "name"
        },
        { text: "Unit", value: "unit.name" },
        { text: "Description", value: "description" },
        { text: "Actions", align: "end", sortable: false, value: "action" }
      ],
      units: [
        { name: "Litre", id: 1 },
        { name: "Barrel", id: 2 },
        { name: "Kilowatt", id: 3 }
      ],
      streams: []
    };
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    getColor(status) {
      if (status == "open") return "bg-danger";
      else if (status == "pending") return "bg-warning";
      else return "bg-success";
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this waste stream",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WasteStreamService.delete(id)
            .then(response => {
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Waste Stream Deleted",
            "Waste Stream was successfully deleted"
          );
          this.getWasteStream();
        }
      });
    },
    viewDetail(wasteStream) {
      this.name = wasteStream.name;
      this.description = wasteStream.description;
      this.emission_factor = wasteStream.emission_factor;
      this.unit_name = wasteStream.unit.name;
      this.$refs["item-detail"].show();
    },
    hideDetail() {
      this.name = "";
      this.description = "";
      this.emission_factor = "";
      this.unit_name = "";
      this.$refs["item-detail"].hide();
    },
    editItem(id) {
      this.$router.push(`/waste-streams/edit/${id}`);
    },
    getWasteStream() {
      WasteStreamService.all().then(response => {
        this.streams = response.data;
      });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Waste Streams" }]);
    this.getWasteStream();
  }
};
</script>
