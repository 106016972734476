import axios from "axios";

const WasteStreamService = {
  /**
   * Creates new action party
   * @param  {} params
   */
  create(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("waste-streams", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Gets an action party
   * @param  {} id
   */
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`waste-streams/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get all action parties
   * @param  {} slug
   */
  all(slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`waste-streams?${slug}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Updates an action party
   * @param  {} id
   * @param  {} params
   */
  update(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`waste-streams/${id}`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Deletes an action party
   * @param  {} id
   */
  delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`waste-streams/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default WasteStreamService;
